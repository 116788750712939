import gtag from '@/features/tracking/gtag'

export const sendRecommendationsSubmitEvent = async () => {
  gtag.internal.sendEvent({ name: 'send_recommendations_modal' })
}

export const sendRecommendationsStepChangeEvent = async (step: number) => {
  gtag.internal.sendEvent({
    name: 'send_recommendations_modal_progressing',
    params: { step },
  })
}
