import { FooterSectionType } from '.'

const getLanguageLearning = (): FooterSectionType => {
  return {
    titleI18Key: 'footer.language_learning.title',
    links: [
      {
        i18Key: 'footer.language_learning.english',
        href: '/topic/english',
      },
      {
        i18Key: 'footer.language_learning.korean',
        href: '/search?subject=korean',
      },
      {
        i18Key: 'footer.language_learning.japanese',
        href: '/search?subject=japanese',
      },
      {
        i18Key: 'footer.language_learning.french',
        href: '/search?subject=french',
      },
      {
        i18Key: 'footer.language_learning.german',
        href: '/search?subject=german',
      },
      {
        i18Key: 'footer.language_learning.italian',
        href: '/search?subject=italian',
      },
    ],
  }
}

export default getLanguageLearning
