const safelyParseJson = <T = unknown>(json: string | null | undefined): T | undefined => {
  let result: T | undefined

  try {
    result = JSON.parse(json!) as T
  } catch (err) {
    result = undefined
  }

  return result
}

export default safelyParseJson
