import type { FilterType } from './Form/types'
import dynamic from 'next/dynamic'
import { ReactNode, useMemo } from 'react'
import cookie from '@/libs/cookie'
import { useMobileUpMediaQuery } from '@/hooks/use-mobile-up-media-query'
import { RECOMMENDATION_FILTERS } from './Form/utils'
import RecommendationsForm from './Form'
import useInit from './use-init'

const Modal = dynamic(import('@/components/modals'))
const Drawer = dynamic(import('@/components/drawer'), { ssr: false })

type Props = {
  children: (action: { onOpen: () => void; onClose: () => void }) => ReactNode
  onSubmit: (searchParams: URLSearchParams) => void
}

const RecommendationsPicker = ({ children, onSubmit }: Props) => {
  const isMobileUp = useMobileUpMediaQuery()
  const { store, action } = useInit()
  const FormComponent = useMemo(
    () => (
      <RecommendationsForm
        key={`recommendations-${store.isModalOpened}`}
        onSubmit={searchParams => {
          onSubmit(searchParams)
          action.onClose()
        }}
        initialValue={cookie.get<FilterType>(RECOMMENDATION_FILTERS, true)}
      />
    ),
    [onSubmit, action, store.isModalOpened],
  )

  return (
    <>
      {isMobileUp && (
        <Modal
          open={store.isModalOpened}
          onClose={action.onClose}
          maxWidth='500px'
          maxHeight='90%'
          padding='1.25rem'
          mainBlockStyles={{ height: '564px' }}
        >
          <>{FormComponent}</>
        </Modal>
      )}
      {!isMobileUp && (
        <Drawer
          isOpened={store.isModalOpened}
          onClose={action.onClose}
          bgClasses='max-h-[80vh] h-full'
        >
          <div className='flex-grow overflow-hidden rounded-t-2xl bg-white px-4 py-5 shadow-xl'>
            {FormComponent}
          </div>
        </Drawer>
      )}
      {children(action)}
    </>
  )
}
export default RecommendationsPicker
