import Box from '@mui/material/Box'
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from '@mui/material/LinearProgress'
import { paletteColors } from '@wordup/assets/colors'
import { ProgressWithNativeCss, type ProgressWithNativeCssProps } from './ProgressWithNativeCss'

type ProgressbarPropTypes =
  | ({ noMui?: false } & LinearProgressProps & {
        percentage: number
        bgColor?: keyof typeof paletteColors
      })
  | ({ noMui: true } & ProgressWithNativeCssProps)

const Progressbar = (props: ProgressbarPropTypes) => {
  if ('noMui' in props && props.noMui) {
    return (
      <ProgressWithNativeCss
        percentage={props.percentage}
        height={props.height}
        color={props.color}
        backgroundColor={props.backgroundColor}
      />
    )
  }

  const { percentage, bgColor, ...restProps } = props

  return (
    <Box
      sx={{
        display: 'flex',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        justifyContent: 'space-between',
      }}
    >
      <LinearProgress
        value={percentage > 100 ? 100 : percentage}
        variant='determinate'
        sx={{
          width: '100%',
          borderRadius: '0.5rem',
          height: '10px;',
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: paletteColors['grayscale-200'],
          },
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: paletteColors[bgColor || 'peach-500'],
          },
        }}
        {...restProps}
      />
    </Box>
  )
}

export { Progressbar }
