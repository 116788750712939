import type { FilterType } from '../types'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import safelyParseJson from '@/libs/utils/safely-parse-json'
import { isWuAppWebview } from '@libs-components/utils/device'
import { Text } from '@libs-components/components/text'
import { Progressbar } from '@libs-components/components/progressbar/shop-progressbar'
import { STEPS } from '../utils'

const STEP_TYPE: Record<number, 'subjects' | 'exams' | 'kinds'> = {
  [STEPS.STEP1]: 'subjects',
  [STEPS.STEP2]: 'exams',
  [STEPS.STEP3]: 'kinds',
}

const Header = ({
  subject,
  currentStep,
}: {
  subject: FilterType['subject']
  currentStep: number
}) => {
  const { t } = useTranslation()
  const characterImgUrl = t('crowdin:recommendations-assistance.character-image-url')
  const message = safelyParseJson<{ web: string; app: string }>(
    t(`crowdin:recommendations-assistance.${STEP_TYPE[currentStep]}-title`, {
      subject: subject.label,
    }),
  )?.[isWuAppWebview() ? 'app' : 'web']

  return (
    <header>
      <div className='mb-4 flex items-center justify-center gap-1'>
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className='flex-grow'>
            <Progressbar
              noMui
              percentage={currentStep >= index ? 100 : 0}
              color='primary-500'
              height={5}
            />
          </div>
        ))}
      </div>
      <div className='flex items-center justify-center gap-1'>
        {characterImgUrl && (
          <Image src={characterImgUrl} alt='recommendation character' width={74} height={70} />
        )}
        {message && (
          <Text
            variant='body_m_400'
            className='rounded-3xl bg-white px-4 py-2 shadow-sm'
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    </header>
  )
}
export default Header
