import type { FooterSectionType } from '.'

const getProductAndService = (): FooterSectionType => {
  return {
    titleI18Key: 'footer.product_and_services.title',
    links: [
      {
        i18Key: 'footer.product_and_services.online_courses',
        href: '/search?tag_or=%E7%B7%9A%E4%B8%8A%E8%AA%B2%E7%A8%8B',
      },
      {
        i18Key: 'footer.product_and_services.e_books',
        href: '/search?tag_or=%E9%9B%BB%E5%AD%90%E6%9B%B8',
      },
      {
        i18Key: 'footer.product_and_services.subscription_plan',
        href: '/search?tag_or=%E8%A8%82%E9%96%B1%E5%88%B6',
      },
      {
        i18Key: 'footer.product_and_services.blog_articles',
        href: 'https://blog.wordup.com.tw/',
      },
      {
        i18Key: 'footer.product_and_services.cashback_program',
        href: '/search?event=%E8%BF%94%E7%8F%BE%E8%A8%88%E7%95%AB',
      },
    ],
  }
}

export default getProductAndService
