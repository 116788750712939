import type { FooterSectionType } from '../sections'
import { useTranslation } from 'next-i18next'
import { Link } from '../../link'
import { Text } from '../../text'

type LinkSectionProps = {
  section: FooterSectionType
}

const LinkSection = ({ section }: LinkSectionProps) => {
  const { t } = useTranslation()
  const { titleI18Key, links } = section

  return (
    <div className='flex h-full flex-col gap-[6px]'>
      <Text variant='body_m_500' color='grayscale-50'>
        {t(titleI18Key)}
      </Text>

      <div className='flex flex-col gap-2'>
        {links.map((link, index) => {
          const text = t(link.i18Key)
          const key = `${index}-${text}`

          if ('onClick' in link) {
            return (
              <button
                key={key}
                className='flex w-fit cursor-pointer justify-start border-none bg-transparent p-0'
                onClick={link.onClick}
              >
                <Text
                  variant='label_s_400'
                  className='text-left'
                  color='grayscale-400'
                  key={`${index}-${text}`}
                >
                  {text}
                </Text>
              </button>
            )
          }

          return (
            <Link key={key} className='w-fit' aria-label={text} href={link.href} target='_self'>
              <Text variant='label_s_400' color='grayscale-400'>
                {text}
              </Text>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default LinkSection
