import { memo } from 'react'
import { StepComponents } from './utils'
import useInit, { type Params } from './use-init'
import Header from './Header'

const RecommendationsForm = (props: Params) => {
  const {
    store: { currentStep, filterQuery },
    action: { updateStep, submit, onChanges },
  } = useInit(props)
  const StepComponent = StepComponents[currentStep]

  return (
    <div className='flex h-full flex-col gap-4'>
      <Header currentStep={currentStep} subject={filterQuery.subject} />
      <StepComponent
        {...onChanges}
        filterQuery={filterQuery}
        updateStep={updateStep}
        onSubmit={submit}
      />
    </div>
  )
}

export default memo(RecommendationsForm)
