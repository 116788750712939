import Image from 'next/image'
import Link from 'next/link'
import { STORE_URL } from '../../../assets/app-store-url'
import { PLATFORM_NAME } from './constants'

const DOWNLOAD_IMAGE = {
  ios: '/app_store.png',
  android: '/play_store.png',
}

const AppStoreLink = ({
  platform,
  sendEvent,
}: {
  platform: 'ios' | 'android'
  sendEvent?: () => void
}) => {
  return (
    <Link
      target='_blank'
      rel='noreferrer noopener'
      href={STORE_URL[platform]}
      className='flex items-center'
      onClick={sendEvent}
    >
      <Image
        unoptimized //set to unoptimized to avoid custom loader, img is from public folder
        src={DOWNLOAD_IMAGE[platform]}
        alt={`WORD UP ${PLATFORM_NAME[platform]} App download link`}
        width={125}
        height={37.5}
        className='h-auto min-w-full max-w-full'
      />
    </Link>
  )
}

export default AppStoreLink
