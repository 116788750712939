import Image from 'next/image'

const DumplingPopcornSilhouette = () => {
  return (
    <div className='mobileUp:right-[85px] absolute right-[24.7px] top-[-44px]'>
      <Image
        src='https://public.wordup.com.tw/app_assets/dumpling_popcorn_+silhouette.png'
        height={174}
        width={299}
        className='max-h-[44px] w-auto'
        alt='silhouette'
      />
    </div>
  )
}

export default DumplingPopcornSilhouette
