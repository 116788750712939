import type { FilterType } from './types'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  sendRecommendationsSubmitEvent,
  sendRecommendationsStepChangeEvent,
} from '@/features/tracking/events/recommendations-form'
import { STEPS, DEFAULT_FILTER, setRecommendationFilters, getSearchParams } from './utils'

export type Params = {
  onSubmit: (searchParams: URLSearchParams) => void
  initialValue?: FilterType
}
const useInit = ({ onSubmit, initialValue }: Params) => {
  const router = useRouter()
  const [currentStep, setCurrentStep] = useState<number>(STEPS.STEP1)
  const [filterQuery, setFilterQuery] = useState(DEFAULT_FILTER)
  const onChanges = useMemo(
    () => ({
      onSubjectChange: (value: FilterType['subject']) => {
        setFilterQuery(prev => ({ ...prev, subject: value, catExam: DEFAULT_FILTER.catExam }))
      },
      onExamChange: (value: FilterType['catExam']) => {
        setFilterQuery(prev => ({ ...prev, catExam: value, catKind: DEFAULT_FILTER.catKind }))
      },
      onKindChange: (value: FilterType['catKind']) => {
        setFilterQuery(prev => ({ ...prev, catKind: value }))
      },
    }),
    [],
  )
  const updateStep = useCallback((step: number) => {
    setCurrentStep(Math.max(STEPS.STEP1, Math.min(step, STEPS.STEP3)))
  }, [])
  const submit = () => {
    setRecommendationFilters(filterQuery)
    sendRecommendationsSubmitEvent()
    onSubmit(getSearchParams(filterQuery))
  }

  useEffect(() => {
    if (!router.isReady) return
    if (initialValue) setFilterQuery(initialValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  useEffect(() => {
    sendRecommendationsStepChangeEvent(currentStep + 1)
  }, [currentStep])

  return {
    store: { currentStep, filterQuery },
    action: { updateStep, submit, onChanges },
  }
}

export default useInit
