import { twMerge } from 'tailwind-merge'
import { paletteColors, type PaletteColorKeysType } from '@wordup/assets/colors'

type ProgressWithNativeCssProps = {
  percentage: string | number
  height?: string | number
  color?: PaletteColorKeysType
  backgroundColor?: PaletteColorKeysType
}

// Do not use this component directly,
// this is compatible with the MUI Progressbar
// so use `shop-progressbar` instead
const ProgressWithNativeCss = ({
  percentage,
  height = 8,
  color = 'peach-500',
  backgroundColor = 'grayscale-100',
}: ProgressWithNativeCssProps) => {
  return (
    <div
      aria-label='progress-bar'
      className={twMerge(
        'relative w-full overflow-hidden rounded-lg',
        'before:absolute before:left-0 before:top-0 before:block before:h-full   before:transition-all before:duration-300 before:ease-in-out before:content-[""]',
        'h-[var(--wu-progress-height)] bg-[var(--wu-progress-bg-color)]',
        'before:w-[var(--wu-progress-width)] before:bg-[var(--wu-progress-color)]',
      )}
      style={{
        // @ts-expect-error, add dynamic css variables in this div scope;
        '--wu-progress-width':
          typeof percentage === 'string'
            ? percentage
            : `${Number.isFinite(percentage) ? percentage : 0}%`,
        '--wu-progress-height': typeof height === 'string' ? height : `${height}px`,
        '--wu-progress-color': paletteColors[color],
        '--wu-progress-bg-color': paletteColors[backgroundColor],
      }}
    />
  )
}

export { ProgressWithNativeCss, type ProgressWithNativeCssProps }
