export const ifAbsUrl = (str: string): boolean => {
  const r = new RegExp('^(?:[a-z]+:)?//', 'i')
  return r.test(str)
}

export const isVimeoUrl = (url: string): boolean => {
  if (!url) {
    return false
  }

  const vimeoRegex = /vimeo\.com/
  return vimeoRegex.test(url)
}
